angular.module('angus.controllers').controller('tmsDealerExceptionsActionViewCtrl', [
    '$rootScope', '$scope', '$q', 'Paging', 'modalService', 'actionViewTemplates', 'fluentRest', 'moment', 'promiseMonitor', 'constantsService', '$filter', '_', 'csvService', 'windowsService',
    function($rootScope, $scope, $q, paging, modalService, actionViewTemplates, fluentRest, moment, promiseMonitor, constantsService, $filter, _, csvService, windowsService) {
        'use strict';

        windowsService.setCallingScopeCallBack($scope.windowId, function(newCallingScope){
            const newSelectedDealerIds = newCallingScope.model && newCallingScope.model.activeDealerIds;

            if (newSelectedDealerIds) {
                $scope.selected.dealerIds = newSelectedDealerIds;
            }
            else {
                $scope.selected.dealerIds = selectAllDealers();
            }

            $scope.getExceptions();
            
        });

        function getDealers() {
            return fluentRest
                .api()
                .subscribers()
                .dealers()
                .get()
                .then(function(dealers) {
                    $scope.dealers = dealers;
                });
        }

        function selectAllDealers() {
            return _.map($scope.dealers, function(dealer) {
                return dealer.id;
            });
        }

        function ExceptionStatus(status) {
            this.name = status.value.display;
            this.id = status.value.id;
            this.paging = new paging(100);
            this.sortBy = 'date';
            this.ascending = true;

            this.setExceptions = function(exceptions) {
                this.exceptions = exceptions;
            };

            this.clearExceptions = function() {
                delete this.exceptions;
            };

            this.setTotal = function(total) {
                this.total = total;
            };

            this.clearTotal = function() {
                delete this.total;
            };

            this.clear = function() {
                this.clearExceptions();
                this.clearTotal();
            };

        }

        function getExceptionTypes() {
            return fluentRest
                .api()
                .subscribers()
                .dealers()
                .exceptions()
                .types()
                .get({eg : 'tms'})
                .then(function(exceptionTypes) {
                    $scope.exceptionTypes = exceptionTypes;
                    return $scope.exceptionTypes;
                });
        }

        function getExceptions(exceptionStatus, pageNumber) {

            var params = {
                sortBy: exceptionStatus.sortBy,
                ascending: exceptionStatus.ascending ? 1 : 0,
                s: exceptionStatus.id,
                d: $scope.selected.dealerIds,
                t: $scope.selected.types.length === 0 ? _.map($scope.exceptionTypes, 'id') : $scope.selected.types,
                sd: $scope.dateRange.start,
                ed: $scope.dateRange.end,
            };

            var url = fluentRest
                .api()
                .subscribers()
                .dealers()
                .exceptions()
                .toString();

            var promise = exceptionStatus.paging.postGetPage(url + "/list", pageNumber, params)
                .then(function(exceptions) {
                    exceptionStatus.setExceptions(exceptions.items);
                }, function() {
                    exceptionStatus.setExceptions([]);
                });

            $scope.pageMonitor.monitor(promise);
        }

        $scope.exportToCsv= function(exceptionStatus) {
            var params = {
                sortBy: exceptionStatus.sortBy,
                ascending: exceptionStatus.ascending ? 1 : 0,
                s: exceptionStatus.id,
                d: $scope.selected.dealerIds,
                t: $scope.selected.types.length === 0 ? _.map($scope.exceptionTypes, 'id') : $scope.selected.types,
                sd: $scope.dateRange.start,
                ed: $scope.dateRange.end,
            };

            var url = fluentRest.api().subscribers().dealers().exceptions().toString();

            csvService.postGetCsv(url + "/list", params, 'TMS Exceptions');

        };


        function getExceptionTotals() {
            var params = {
                d: $scope.selected.dealerIds,
                t: $scope.selected.types.length === 0 ? _.map($scope.exceptionTypes, 'id') : $scope.selected.types,
                sd: $scope.dateRange.start,
                ed: $scope.dateRange.end
            };

            return fluentRest
                .api()
                .subscribers()
                .dealers()
                .exceptions()
                .totals()
                .get(params)
                .then(function(data) {
                    var totals = _.reduce(data, function(result, row){
                        result.pending  += row.pending.total;
                        result.resolved += row.resolved.total;
                        return result;
                    }, {
                        pending : 0,
                        resolved :0
                    })
                    $scope.pending.setTotal(totals.pending);
                    $scope.resolved.setTotal(totals.resolved);
                })
                .catch(function() {
                    $scope.unresolved.setTotal(0);
                    $scope.resolved.setTotal(0);
                });

        }

        function clearExceptions() {
            _.forEach($scope.exceptionStatuses, function(exceptionStatus) {
                exceptionStatus.clear();
            })
        }


        function clearFilters() {
            $scope.dateRange = {
                //start : moment().subtract(7, 'days').toDate(),
                //end   : moment().toDate(),
            };
        }

        $scope.clearFilters = function() {
            clearFilters();
            $scope.getExceptions();
        };

        function init() {
            $scope.promiseMonitor = new promiseMonitor();
            $scope.pageMonitor = new promiseMonitor();
            $scope.exceptionMonitor = new promiseMonitor();
            $scope.today = moment.utc().toDate();

            $scope.pending = new ExceptionStatus(constantsService.exceptionStatus.pending);
            $scope.resolved = new ExceptionStatus(constantsService.exceptionStatus.resolved);
            $scope.exceptionStatuses = [$scope.pending, $scope.resolved];
            $scope.activeExceptionStatus = $scope.pending;

            clearFilters();


            var promise = $q.all([
                    getDealers(),
                    getExceptionTypes($scope.actionModel.exceptionGroups)
                ])
                .then(function() {
                    $scope.selected = {
                        dealerIds: $scope.activeDealerIds || selectAllDealers(),
                        types: []
                    };

                    return;
                })
                .then(function() {
                    return $q.all([
                        getExceptionTotals(),
                        getExceptions($scope.activeExceptionStatus)
                    ]);
                });

            $scope.promiseMonitor.monitor(promise);
        }

        init();

        $scope.getExceptions = function() {
            clearExceptions();
            return $q.all([
                getExceptionTotals(),
                getExceptions($scope.activeExceptionStatus)
            ]);

        }

        // $scope.getExceptionTypes

        $scope.getExceptionsPage = function(pageNumber) {
            getExceptions($scope.activeExceptionStatus, pageNumber);
        };

        $scope.changeExceptionStatus = function(exceptionStatus) {
            $scope.activeExceptionStatus = exceptionStatus;
            if (!exceptionStatus.exceptions) {
                getExceptions(exceptionStatus);
            }
        };

        $scope.sortBy = function(sortBy) {
            if ($scope.activeExceptionStatus.sortBy == sortBy)
                $scope.activeExceptionStatus.ascending = !$scope.activeExceptionStatus.ascending;
            else {
                $scope.activeExceptionStatus.sortBy = sortBy;
                $scope.activeExceptionStatus.ascending = true;
            }

            getExceptions($scope.activeExceptionStatus);
        };


        function stopEvent($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }


        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.startDateOpened = false;
        $scope.endDateOpened = false;

        $scope.openStartDate = function($event) {
            stopEvent($event);
            this.endDateOpened = false;
            this.startDateOpened = true;
        };
        $scope.openEndDate = function($event) {
            stopEvent($event);
            this.startDateOpened = false;
            this.endDateOpened = true;
        };

        $scope.resetEndDate = function() {
            if (moment(this.dateRange.end).isBefore(moment(this.dateRange.start)))
                this.dateRange.end = this.dateRange.start;
        };

        $scope.checkAll = function(exceptions) {
            _(exceptions).forEach(function(e) {
                e.selected = true;
            });
        };

        $scope.checkNone = function(exceptions) {
            _(exceptions).forEach(function(e) {
                e.selected = false;
            });
        };

        $scope.anyChecked = function(exceptions) {
            if (exceptions) {
                return _(exceptions).some(function(e) {
                    return e.selected;
                });
            }
            return false;
        };


        $scope.addComment = function(exception) {

            var scope = {
                container: {
                    id: exception.containerId,
                    name: exception.bosTankId
                },
                customer: {
                    name: exception.customerName.first + ' ' + exception.customerName.last
                },
                exception: exception,
                comment: null
            };

            return modalService
                .openTopBarModal(actionViewTemplates.paygoCustRecAddComment, scope)
                .spread(function(comment) {
                    if (!comment)
                        return;


                    return fluentRest
                        .api()
                        .subscribers(exception.dealer.subscriberId)
                        .customerAccounts(exception.customerId)
                        .containers(exception.containerId)
                        .comments()
                        .post({
                            comment: comment, 
                            exception: exception
                        })
                        .then(function() {
                            exception.comment = {
                                body: comment,
                                user: $rootScope.user.firstName + ' ' + $rootScope.user.lastName,
                                date: moment().format()
                            }
                            exception.numberOfComments = exception.numberOfComments + 1;
                        });
                });
        };


        $scope.openCommentsModal = function(exception) {
            var scope = $scope.$new();
            //scope.addComment = $scope.addComment;
            scope.close = $scope.closeCommentsModal;
            scope.exception = exception;

            $scope.commentsModalInstance = modalService.open({
                templateUrl: actionViewTemplates.paygoExceptionsComments,
                scope: scope
            }, modalService.types.topBar);
        };

        function openConfirmModal(exceptions, status, heading, verb) {


            var exceptions = _.filter(exceptions, function(ex) {
                return (ex.selected);
            });

            if (exceptions.length == 0)
                return;

            var scope = {
                exceptions: _.filter(exceptions, function(ex) {
                    return (ex.selected);
                }),
                heading: heading,
                verb: verb
            };


            return modalService
                .openTopBarModal(actionViewTemplates.paygoExceptionsConfirm, scope)
                .spread(function(exceptions, comment) {

                    if (!exceptions)
                        return;

                    var postData = {
                        exceptionsByDealer: _.groupBy(exceptions, function(exception) {
                            return exception.dealer.id;
                        }),
                        dealerIds: _.map(exceptions, function(exception) {
                            return exception.dealer.id;
                        }),
                        comment: comment
                    };

                    return fluentRest
                        .api()
                        .subscribers()
                        .dealers()
                        .exceptions()[status.toLowerCase()]()
                        .post(postData)
                        .then($scope.getExceptions);
                });
        }

        $scope.confirmIgnoreChecked = function(exceptions) {
            openConfirmModal(exceptions, 'ignore', 'Ignore exceptions', 'ignored');
        };

        $scope.confirmResolveChecked = function(exceptions) {
            openConfirmModal(exceptions, 'resolve', 'Resolve exceptions', 'resolved');
        };

        $scope.confirmMarkAsNew = function(exceptions) {
            openConfirmModal(exceptions, 'new', 'Mark exceptions as new', 'marked as new');
        };

        $scope.viewComments = function(exception) {

            $scope.retrievingComments = true;

            var url = fluentRest
                .api()
                .subscribers(exception.dealer.subscriberId)
                .dealers(exception.dealer.id)
                .exceptions(exception.id)
                .comments()
                .toString();

            var commentPaging = new paging(10);
 
                    return commentPaging
                        .getPage(url)
                        .then(function(comments) {

                            var scope = {
                                subscriberId: exception.dealer.subscriberId,
                                exceptionId: exception.id,
                                exception: exception,
                                dealerId: exception.dealer.id,
                                gettingComments: false,
                                numberDeleted: 0,
                                display: ('Exception on {0}').format($filter('utcDate')(exception.date)),
                                paging: commentPaging,
                                comments: comments.items, 
                                selected: { 
                                },
                                getCommentsPage: function(pageNumber, that) {
                                    that = that || this;
                                    that.gettingComments = true;
                                    return commentPaging
                                        .getPage(url, pageNumber, { 
                                        })
                                        .then(function(comments) {
                                            that.comments = comments.items;
                                            that.gettingComments = false;
                                        });
                                },
                                deleteComment: function(comment) {
                                    var that = this.$parent;
                                    var scope = {
                                        title: 'Delete Comment?',
                                        message: ('Are you sure you want to delete the comment on {0} by {1}?').format($filter('utcDate')(comment.timestamp), comment.user)
                                    };

                                    modalService
                                        .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                                        .then(function(response) {
                                            if (!response)
                                                return;

                                            return fluentRest
                                                .api()
                                                .subscribers(that.subscriberId)
                                                .dealers(that.dealerId)
                                                .exceptions(that.exceptionId)
                                                .comments(comment.id)
                                                .delete()
                                                .then(that.getCommentsPage(null, that))
                                                .then(function() {
                                                    that.numberDeleted = that.numberDeleted + 1;
                                                });
                                        });
                                }

                            };

                            return modalService
                                .openTopBarModal(actionViewTemplates.paygoCustRecViewComments, scope)
                                .then(function(numberDeleted) {
                                    if (numberDeleted)
                                        exception.numberOfComments = exception.numberOfComments - numberDeleted;
                                    $scope.retrievingComments = false;
                                });

                        }); 
        };

        function getCustomerById(subscriberId, customerId) {
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .customerAccounts(customerId)
                .paygo()
                .get();
        }

        function getMonitor(subscriberId, containerKey){

            return fluentRest
                .api()
                .subscribers(subscriberId)
                .containers(containerKey)
                .monitors()
                .get();
        }

        function openTMSMonitorAdmin (dealer, customerId, containerId){
            getMonitor(dealer.subscriberId, customerId, containerId)
                .then(function(monitor) {
                    if (monitor) {
                        var scope = {
                            widgetName: 'TMS Daily Levels',
                            widgetIcon: 'map',
                            widgetId: constantsService.widgets.paygoTMSAdmin.value.id,
                            widgetCode: constantsService.widgets.paygoTMSAdmin.value.code,
                            customer :customerId,
                            monitor: monitor,
                            subscriber: {id: dealer.subscriberId}
                        };
                        localStorage.setItem('activeItem', JSON.stringify(monitor));
                        modalService
                            .openActionView('templates/widgets/tmsDailyLevels/html/actionViews/tmsDailyLevelsActionView.html', scope, constantsService.widgets.paygoTMSAdmin.value.id);
                    }
                });
        }

        $scope.openCustomer = function(dealer, customerId, containerId, containerKey, exception) {
            $scope.highlightedException = exception;
            openTMSMonitorAdmin(dealer, containerKey)
        };




    }
]);
